import { Auth } from '@aws-amplify/auth'
import { readCurrentOrganization } from './shared/utils/storageUtils'
import { REST_API_NAME, DOWNLOAD_API_NAME, UPDATE_HEADERS_AMPLIFY_PROP } from './shared/api/constants'
import config from './shared/utils/awsConfig'

const basePath = new URL(window.location.href).origin

const uiPath = `${basePath}/api-portal`

const getJwtToken = async () => {
  try {
    const session = await Auth.currentSession()
    return session.getAccessToken().getJwtToken()
  } catch (error) {
    console.error('Error getting JWT token:', error)
    return null // Handle error appropriately
  }
}

const getCurrentOrganizationExternalId = () => {
  const currentOrganization = readCurrentOrganization()
  return currentOrganization?.externalId
}

const restAPI = {
  name: REST_API_NAME,
  endpoint: `${basePath}/api`,
  [UPDATE_HEADERS_AMPLIFY_PROP]: async () => {
    const headers: Record<string, unknown> = {
      Authorization: 'Bearer ' + (await getJwtToken()),
    }

    const organizationId = getCurrentOrganizationExternalId()
    if (organizationId) {
      headers['x-org'] = organizationId
    }

    return headers
  },
}

const downloadAPI = {
  name: DOWNLOAD_API_NAME,
  endpoint: `${basePath}/download`,
  [UPDATE_HEADERS_AMPLIFY_PROP]: async () => {
    const headers = {
      Authorization: 'Bearer ' + (await getJwtToken()),
    }
    return headers
  },
}

export const amplifyConfig = {
  Auth: {
    region: config.userPoolId.split('_')[0],
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    oauth: {
      domain: config.cognito,
      scope: ['openid'],
      redirectSignIn: `${uiPath}/login`,
      redirectSignOut: `${uiPath}/logout`,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [restAPI, downloadAPI],
  },
}
