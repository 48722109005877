export const theme = {
  inherit: true,
  token: {
    colorPrimary: '#0071B9',
    colorLink: '#0C9FED',
    colorBgDisabled: '#566066',
    colorBorderDisabled: '#566066',
    colorTextDisabled: '#AAAFB3',
    fontFamily: `'KONE Information', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
    fontSize: 16,
    colorBgLayout: '#11161a',
    colorBgBase: '#11161a',
    colorBgFooter: '#11161a',
    colorText: '#fff',
    borderRadius: 4,
    colorLightBlue: '#4dacff',
    colorError: '#ed4337',
    colorBgContainer: '#fff',
    fontSizeLG: 18,
    Button: {
      primaryShadow: 'none',
      colorPrimaryDisabled: '#aaafb3', // Text color for disabled solid buttons
      colorBgContainerDisabled: '#566066',
    },
    colorFill: '#ffffff', // Replace with your desired background color
  },
  components: {
    Menu: {
      itemColor: '#c6cacc',
      colorSubMenuTitleText: '#c6cacc',
      colorBgContainerDisabled: '#566066', // Background color for disabled inputs
      colorBorder: '#d9d9d9', // Border color for disabled inputs
    },
    Select: {
      // Customize disabled styles for inputs
      colorBgContainer: '#fff',
      colorBgContainerDisabled: '#566066', // Background color for disabled inputs
      colorBorder: '#d9d9d9', // Border color for disabled inputs
      colorTextPlaceholder: '#fff',
    },
    Input: {
      // Customize disabled styles for inputs
      colorBgContainerDisabled: '#566066', // Background color for disabled inputs
      colorBorder: '#d9d9d9', // Border color for disabled inputs
      colorText: '#000',
    },
    Tabs: {
      fontSize: 16,
      fontFamily: `'KONE Information', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
    },
    Calendar: {
      colorBgContainer: '#fff',
    },
  },
}
