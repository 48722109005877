import ReactDOM from 'react-dom/client'
import { Amplify } from '@aws-amplify/core'
import { RestAPI as AmplifyAPI } from '@aws-amplify/api-rest'
import { lazy, Suspense } from 'react'
import './global.css'
import 'normalize.css'
import 'antd/dist/reset.css'
const App = lazy(() => import('./App'))
import initReportingObserver from './shared/utils/initReportingObserver'
import { ConfigProvider } from 'antd'
import { theme } from './themeConfig'
import { amplifyConfig } from './amplifyConfig'

initReportingObserver()
Amplify.configure(amplifyConfig)
AmplifyAPI.configure(amplifyConfig)

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <Suspense fallback={<div>Loading...</div>}>
    <ConfigProvider theme={theme}>
      <App />
    </ConfigProvider>
  </Suspense>,
)
